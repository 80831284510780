import React from 'react';
import { ExternalLink, Github, FolderOpen } from 'lucide-react';
import "../styles/Projects.css";
const ProjectShowcase = () => {
  const projects = [
    {
      id:1,
      title: "ByteDental",
      desc: "Optimized, automated marketing solutions for dental practices. \n",
      techStack: ["Next.js", "MongoDB", "AWS"],
      image: "/assets/teethxray.jpg",
      links: {
        github: "https://github.com/mohammad-kk/Dental-App",
        live: "https://bytedental.pro"
      }
    },
    {
      
      id: 2,
      title: "Minecraft Intelligence",
      desc: "AI Agents in Minecraft.\n ",
      techStack: ["JavaScript", "OpenAI", "LLM",],
      image: "/assets/mcthumbnail.jpg",
      links: {
        github: "https://github.com/kolbytn/mindcraft",
        // live: "https://www.slidingscalehealth.org/financial-assistance"
      }
    }

  ];

  const ProjectCard = ({ project }) => (
    <div className="project-card">
      <div className="project-image-container">
        <img src={project.image} alt={project.title} className="project-image" />
      </div>
      <div className="project-content">
        <div className="project-header">
          <h3 className="project-title">{project.title}</h3>
          <div className="project-links">
            {project.links.github && (
              <a
                href={project.links.github}
                target="_blank"
                rel="noopener noreferrer"
                className="project-link"
              >
                <Github size={20} />
              </a>
            )}
            {project.links.live && (
              <a
                href={project.links.live}
                target="_blank"
                rel="noopener noreferrer"
                className="project-link"
              >
                <ExternalLink size={20} />
              </a>
            )}
          </div>
        </div>
        <p className="project-description">{project.desc}</p>
        <div className="tech-stack">
          {project.techStack.map((tech, index) => (
            <span key={index} className="tech-tag">
              {tech}
            </span>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div id="projects">
      <div className="section-header">
        <span className="section-title">/ software-creations</span>
      </div>
      <div className="projects-wrapper">
        <div className="project-container">
          {projects.map(project => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectShowcase;
// import React, { useState } from 'react';
// import { ExternalLink, Github, ChevronLeft, ChevronRight } from 'lucide-react';
// import "../styles/Projects.css";

// const ProjectShowcase = () => {
//   const [currentPage, setCurrentPage] = useState(0);
  
//   const projects = [
    // {
    //   id: 1,
    //   title: "Minecraft Intelligence",
    //   desc: "AI Agents in Minecraft.\n ",
    //   techStack: ["Django", "React", "PostgreSQL", "Redis"],
    //   image: "/assets/mcthumbnail.jpg",
    //   links: {
    //     github: "https://github.com/yourusername/financial-assistance",
    //     live: "https://www.slidingscalehealth.org/financial-assistance"
    //   }
    // },
    // {
    //   id: 2,
    //   title: "ByteDental",
    //   desc: "Optimized, automated marketing solutions for dental practices. \n $10K MRR",
    //   techStack: ["React", "Node.js", "MongoDB"],
    //   image: "/assets/teethxray.jpg",
    //   links: {
    //     github: "https://github.com/mohammad-kk/Dental-App",
    //     live: "https://bytedental.pro"
    //   }
    // },
//     {
//       id: 3,
//       title: "AI Code Assistant",
//       desc: "An intelligent coding assistant that helps developers write better code faster.",
//       techStack: ["Python", "TensorFlow", "FastAPI"],
//       image: "/assets/placeholder.jpg",
//       links: {
//         github: "https://github.com/example/ai-code-assistant",
//         live: "https://aicodeassistant.dev"
//       }
//     },
//     {
//       id: 5,
//       title: "EcoTrack",
//       desc: "Environmental monitoring system using IoT sensors and machine learning to track and analyze air quality, water quality, and noise pollution.",
//       techStack: ["Python", "TensorFlow", "Arduino", "AWS"],
//       image: "/assets/placeholder.jpg",
//       links: {
//         github: "https://github.com/example/ecotrack",
//         live: "https://ecotrack.io"
//       }
//     }
//   ];

//   const projectsPerPage = 3;
//   const totalPages = Math.ceil(projects.length / projectsPerPage);

//   const nextPage = () => {
//     setCurrentPage((prev) => (prev + 1) % totalPages);
//   };

//   const prevPage = () => {
//     setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
//   };

//   const ProjectCard = ({ project }) => (
//     <div className="project-card">
//       <div className="project-image-container">
//         <img src={project.image} alt={project.title} className="project-image" />
//       </div>
//       <div className="project-content">
//         <div className="project-header">
//           <h3 className="project-title">{project.title}</h3>
//           <div className="project-links">
//             {project.links.github && (
//               <a
//                 href={project.links.github}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="project-link"
//               >
//                 <Github size={20} />
//               </a>
//             )}
//             {project.links.live && (
//               <a
//                 href={project.links.live}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="project-link"
//               >
//                 <ExternalLink size={20} />
//               </a>
//             )}
//           </div>
//         </div>
//         <p className="project-description">{project.desc}</p>
//         <div className="tech-stack">
//           {project.techStack.map((tech, index) => (
//             <span key={index} className="tech-tag">
//               {tech}
//             </span>
//           ))}
//         </div>
//       </div>
//     </div>
//   );

//   const startIdx = currentPage * projectsPerPage;
//   const visibleProjects = projects.slice(startIdx, startIdx + projectsPerPage);

//   return (
//     <div id="projects">
//       <div className="section-header">
//         <span className="section-title">/ software-creations</span>
//       </div>
//       <div className="carousel-container">
//         <button className="nav-button prev" onClick={prevPage}>
//           <ChevronLeft />
//         </button>
        
//         <div className="project-container">
//           {visibleProjects.map(project => (
//             <ProjectCard key={project.id} project={project} />
//           ))}
//         </div>

//         <button className="nav-button next" onClick={nextPage}>
//           <ChevronRight />
//         </button>
//       </div>

//       <div className="carousel-indicators">
//         {Array.from({ length: totalPages }).map((_, idx) => (
//           <button
//             key={idx}
//             className={`indicator ${currentPage === idx ? 'active' : ''}`}
//             onClick={() => setCurrentPage(idx)}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ProjectShowcase;