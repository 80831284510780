import React, { useState, useEffect, useCallback } from 'react';
import { Sun, Moon } from 'lucide-react';

const ThemeToggle = () => {
  // Initialize theme from localStorage or system preference
  const [isDark, setIsDark] = useState(() => {
    if (typeof window !== 'undefined') {
      const savedTheme = localStorage.getItem('theme');
      if (savedTheme) {
        return savedTheme === 'dark';
      }
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    return true; // Default to dark theme
  });

  // Update theme in DOM and localStorage
  const updateTheme = useCallback((darkMode) => {
    const root = document.documentElement;
    const body = document.body;

    if (darkMode) {
      root.classList.add('dark-theme');
      body.style.backgroundColor = 'var(--dark-navy)';
    } else {
      root.classList.remove('dark-theme');
      body.style.backgroundColor = 'var(--bg-primary)';
    }

    localStorage.setItem('theme', darkMode ? 'dark' : 'light');
  }, []);

  // Initialize theme on mount
  useEffect(() => {
    updateTheme(isDark);
  }, [isDark, updateTheme]);

  // Handle system theme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      if (!localStorage.getItem('theme')) {
        setIsDark(e.matches);
      }
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  const toggleTheme = () => {
    setIsDark(prev => !prev);
  };

  return (
    <button
      onClick={toggleTheme}
      className="theme-toggle"
      aria-label={isDark ? 'Switch to light theme' : 'Switch to dark theme'}
    >
      {isDark ? (
        <Sun size={20} className="transition-transform hover:rotate-45" />
      ) : (
        <Moon size={20} className="transition-transform hover:-rotate-12" />
      )}
    </button>
  );
};

export default ThemeToggle;