import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  } else {
    return {
      id: `vertical-tab-${index}`
    };
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    "Xioten": {
      jobTitle: "Software Engineer @",
      duration: "DEC 2023 - DEC 2024",
      desc: [
        "Architected enterprise integration platform using Golang and React/TypeScript, enabling secure data exchange for healthcare clients.",
        "Developed real-time analytics dashboard processing 100K+ daily transactions with PostgreSQL optimization.",
        "Implemented role-based access control and audit logging system meeting stringent compliance requirements."
      ]
    },
    "Sliding Scale Health": {
      jobTitle: "Software Engineer @",
      duration: "APRIL 2021 - DEC 2023",
      desc: [
        "Piloted a consumer-friendly Django application with AARP, aiding 33+ million users in planning Medicare co-pays, deductibles, and medication spending.",
        "Spearheaded the research, design, and prototyping of software applications, prioritizing healthcare and digital accessibility.",
        "Secured an impressive 2nd place at TechCrunch Disrupt’s Startup Battlefield."
      ]
    },
    // "Citadel": {
    //   jobTitle: "Software Engineer @",
    //   duration: "April 2023 - AUG 2023",
    //   desc: [
    //     "Developed high-throughput data processing pipeline in Golang, handling 100K+ B2B transactions per minute.",
    //     "Built distributed caching system using MongoDB and Redis, reducing API response time by 40%.",
    //     "Created automated monitoring system using AWS CloudWatch and Lambda for B2B service integration."
    //   ]
    // },
    "Pioneers In Engineering": {
      jobTitle: "Engineering Team Lead @",
      duration: "FEB 2021 - MAY 2023",
      desc: [
      "Led development of inter-device communication and streamlined processes for the field control state machine, utilizing LCM pro- tocols in Python.",
      "Spearheaded the development and design of the Pimulator, a vir- tual robotic simulator that revolutionized the learning experience for 20+ high school teams.",
      "Designed and built a new scoreboard and student UI to be used by 20+ high school teams."
      ]
    },
 
    "New England Complex System Institute": {
      jobTitle: "Software Research Engineering @",
      duration: "FEB 2020 - OCT 2020",
      desc: [
        "Collected, studied, and interpreted large datasets; conducted data visualization in a clear and discernable manner, and performed predictive models for regression analysis of spread variability of the virus.",
        "Principal designer for user interfaces for smart tracing applications. ",
        "Relevant technologies/tools used: NumPy, Matplotlib, AutoML, Messenger Bot API, MongoDB."
      ]
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;
